import React from "react";
import { Blog } from "../Blog";
import { graphql } from "gatsby";
import { Seo } from "../../Layout/Seo";
const Categories = ({ data, pageContext, location, lang }) => {
  const categories = data.allStrapiCategories.nodes.filter(
    ({ locale }) => locale === lang,
  );

  const blogsNodes = data.allStrapiBlog.nodes;

  function groupByCategoryId(data) {
    const groupedData = {};

    for (const item of data) {
      const categoryId = item.category.id;
      groupedData[categoryId] = groupedData[categoryId] || [];
      groupedData[categoryId].push(item);
    }

    return groupedData;
  }

  const blogsByCategoryId = groupByCategoryId(blogsNodes);

  //------------------------------------------------
  function getCategoryPathIdMap(categories) {
    const categoryPathIdMap = {};

    for (const category of categories) {
      const idWithoutPrefix = category.id.slice(11); // Remove "Categories_"
      categoryPathIdMap[category.path] = idWithoutPrefix;
    }

    return categoryPathIdMap;
  }

  const categoryPathIdMap = getCategoryPathIdMap(categories);

  //------------------------------------------------

  function getBlogsFromUrl({ url, categoryPathIdMap, blogsByCategoryId }) {
    for (const categoryPath in categoryPathIdMap) {
      if (url.includes(categoryPath)) {
        const categoryId = categoryPathIdMap[categoryPath];
        return blogsByCategoryId[categoryId]; // Return the matching blog objects
      }
    }

    return null; // No matching category found
  }

  let pathname;
  try {
    pathname = decodeURIComponent(location.pathname);
  } catch (error) {
    // If decoding fails, use the original path
    pathname = location.pathname;
  }

  // Example usage
  const url = pathname.replace("/categories/", ""); // Replace with your actual URL

  const matchingBlogs = getBlogsFromUrl({
    url,
    categoryPathIdMap,
    blogsByCategoryId,
  });

  //-------------------------------------------------------

  const urlParts = pathname.split("/");
  const targetGroup = parseInt(urlParts[urlParts.length - 1]) || 1; // Extract group number from URL

  const getBlogGroups = ({ blogs, limit }) => {
    const groups = [];
    let currentGroup = [];
    // Check if blogs is an empty array
    if (!blogs || !blogs.length) {
      return groups;
    }

    for (const blog of blogs) {
      if (currentGroup.length === limit) {
        groups.push(currentGroup);
        currentGroup = [];
      }
      currentGroup.push(blog);
    }

    // Add the last group, even if it has fewer than limit items
    groups.push(currentGroup);

    return groups;
  };

  const blogGroups = getBlogGroups({
    blogs: matchingBlogs,
    limit: 6,
  });
  const desiredGroup = blogGroups[targetGroup - 1]; // Adjust for zero-based indexing

  //-------------------------------------------------------
  const numberOfPages = blogGroups.length; // Get the actual number of pages
  const getSeoData = () => {
    const locateCategory = location.pathname.split("/")[2];

    const match = categories.find(
      ({ path }) =>
        path.toLowerCase() === decodeURIComponent(locateCategory.toLowerCase()),
    );
    return { seoTitle: match?.seoTitle, seoDescription: match?.seoDescription };
  };

  return (
    <Seo
      title={getSeoData().seoTitle}
      description={getSeoData().seoDescription}
    >
      <Blog
        data={{ categories, blogs: desiredGroup }}
        location={location}
        pageContext={pageContext}
        numberOfPages={numberOfPages}
      />
    </Seo>
  );
};

export default Categories;

export const query = graphql`
  query {
    allStrapiCategories(sort: { Title: ASC }) {
      nodes {
        path
        locale
        Title
        seoDescription
        seoTitle
        id
      }
    }
    allStrapiBlog(sort: { date: DESC }) {
      nodes {
        id
        date
        title
        path
        category {
          id
          Title
        }
        thumbnail {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP], quality: 100)
            }
            ext
          }
        }
        text
      }
    }
  }
`;
