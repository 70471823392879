import React, { Component } from "react";

import { Link } from "gatsby";
import { Pager } from "./Pager";
import GatsbyImages from "../../GatsbyImages";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
    };
  }

  componentDidMount() {
    document.getElementById("header").classList.add("stick_header");

    this.setState({ content: this.props.data.blogs });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.blogs) !==
      JSON.stringify(prevProps.data.blogs)
    ) {
      this.setState({ content: this.props.data.blogs });
    }
  }

  render() {
    const path =
      this.props.data.categories[0].locale === "en"
        ? "/en/categories/"
        : "/categories/";

    const pathe =
      this.props.data.categories[0].locale === "en" ? "/en/blog/" : "/blog/";

    let pathname;
    if (!this.props.isBlogPage) {
      try {
        pathname = decodeURIComponent(this.props.location.pathname);
      } catch (error) {
        // If decoding fails, use the original path
        pathname = this.props.location.pathname;
      }
    }

    const pageTitle =
      this.props.pageContext.lang === "es" ? "Nuestras novedades" : "Our news";
    return (
      <div className="faqs_list" id="blog">
        <div className="container">
          <div className="col-12">
            <h1>{pageTitle}</h1>

            <ul className="filter-links">
              {this.props.isBlogPage
                ? this.props.data.categories.map((val, index) => {
                    return (
                      <li key={index}>
                        <Link to={`${path}${val.path}`}>{val.Title}</Link>
                      </li>
                    );
                  })
                : this.props.data.categories.map((val, index) => {
                    return (
                      <li
                        key={index}
                        className={pathname.includes(val.path) ? "active" : ""}
                      >
                        <Link to={`${path}${val.path}`}>{val.Title}</Link>
                      </li>
                    );
                  })}
            </ul>
            <div className="cards">
              <ul className="clearfix d-flex flex-wrap">
                {this.state.content.map((val, index) => {
                  const match = /<p[^>]*>(.*?)<\/p>/i.exec(val.text);
                  const firstPContent = match ? match[0] : "";
                  return (
                    <li className="" key={index}>
                      <Link to={`${pathe}${val.path}`}>
                        <div className="cardWrapper">
                          <div className="card1">
                            <GatsbyImages
                              src={
                                val.thumbnail.localFile.childImageSharp
                                  .gatsbyImageData
                              }
                              ext={val.thumbnail.localFile.ext}
                              className="content-image"
                              alt={val.thumbnail.alternativeText}
                            />
                            <div className="category">
                              <span>{val.category.Title}</span>
                            </div>
                          </div>
                          <div className="card-content">
                            <span>{val.date}</span>
                            <div className="h5">{val.title}</div>
                            <div
                              className="card-text"
                              dangerouslySetInnerHTML={{
                                // __html: val.text,
                                __html: firstPContent,
                              }}
                            ></div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <Pager
                pageContext={this.props.pageContext}
                isBlogPage={this.props.isBlogPage}
                pathname={pathname}
                numberOfPages={
                  this.props.isBlogPage
                    ? this.props.pageContext.numberOfPages
                    : this.props.numberOfPages
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Blog };
